<template>
  <div>
    <div v-if="this.markdown && this.markdown.length > 0" class="markdown-preview-box is-unselectable px-1 py-2" :key="markdown" v-markdown>{{ finalMarkdown }}</div>
    <textarea disabled v-else class="markdown-preview-box mt-1 px-1 py-2" :key="markdown" v-model="finalMarkdown"></textarea>
  </div>
</template>

<script>
export default {
  name: 'MarkdownOutput',
  props: ['markdown'],
  computed: {
    finalMarkdown () {
      if (this.markdown && this.markdown.length > 0) {
        return this.markdown
      }
      return 'LIVE PREVIEW, TYPE ON THE LEFT TO START'
    }
  }
}
</script>

<style>
.markdown-preview-box {
  word-break: break-word;
  min-height: 128px;
  height: 348px;
  width: 100%;
  overflow-y: scroll;
}

textarea {
  resize: none;
}
</style>
