<template>
  <card-component title="Add files" icon="fa-upload">
    <div class="container mx-0">
      <button
        @click="pickFile"
        class="button is-primary mb-2"
      >
        {{ !imageUrl ? 'Select images, videos and mp3 files' : 'Change' }}
      </button>
    </div>
    <input
    type="file"
    style="display: none"
    ref="fileInput"
    accept=".mp3,image/*,video/*"
    @change="filePicked"
    multiple
    >
    <div v-if="!isMultipleFiles" class="mb-5">
      <div v-if="!imageUrl && !mp3Url" class="has-background-light has-text-grey-light py-6 has-text-centered">
        Preview file here
      </div>
      <div class="box" v-bind:class="{ 'is-hidden': !mimeType || !mimeType.includes('image') }">
        <img class="files-image" :src="imageUrl">
      </div>
      <div v-if="mp3 && ext === '.mp3'" class="box" v-bind:class="{'is-hidden': !mp3UrlForPreview }">
        <audio controls="controls">
          <source :src="mp3UrlForPreview" type="audio/mpeg">
        </audio>
      </div>
      <div v-else-if="mp3 && ext === '.pdf'" class="box" v-bind:class="{'is-hidden': !mp3UrlForPreview }">
        <iframe :src="mp3UrlForPreview" width="100%" height="500px">
        </iframe>
      </div>
      <video :key="filename" style="width: 100%; max-height: 450px;" controls v-bind:class="{ 'is-hidden': !mimeType || !mimeType.includes('video') }">
        <source :src="imageUrl" type="video/mp4">
        <source :src="imageUrl" type="video/ogg">
        Your browser does not support the video tag.
      </video>
    </div>
    <div v-if="!isMultipleFiles" class="container has-text-weight-semibold ml-1">
      Name the file (this cannot be changed later)
    </div>
    <b-input v-if="!isMultipleFiles" v-model="filename" type="text" class="mb-3" />
    <div class="container has-text-weight-semibold ml-1">
      Ensure that the file{{ isMultipleFiles ? 's' : '' }} meets these criteria
    </div>
    <div class="container ml-0">
      <label class="checkbox">
        <input v-model="agreement.one" type="checkbox">
        I have/the company has permission to use {{ isMultipleFiles ? 'these' : 'this' }} file{{ isMultipleFiles ? 's' : '' }}.
      </label>
    </div>
    <div class="container ml-0 mb-4">
      <label class="checkbox">
        <input v-model="agreement.two" type="checkbox">
        The file{{ isMultipleFiles ? 's' : '' }} does not contain gore, nudity or sexual references.
      </label>
    </div>
    <div v-if="image && !compressing && (Number(imageSize) >= 20)" class="has-text-danger">
      Failed to compress to a small enough file size. Try again with a smaller file. It must be less than 20 MBs.
    </div>
    <div v-if="mp3 && Number(mp3Size) >= 10" class="has-text-danger">
      .mp3 file is too large ({{ mp3Size }}mb), max file size is 2mb. You can try compressing it online at: https://www.mp3smaller.com/ (not affliated to playtours)
    </div>
    <div v-if="errorMessage" class="is-size-6 has-text-danger">
      {{ errorMessage }}
    </div>
    <button
      v-if="!isMultipleFiles"
      @click="upload"
      class="button is-primary mt-0"
      v-bind:class="{'is-loading': loading }"
      :disabled="!agreed || (!imageUrl && !mp3Url) || exceedsFileSize || !filename || filename.length === 0"
    >
      {{ compressing ? 'Compressing...' : 'Upload file' }}
    </button>
    <button
      v-else
      @click="uploadMultipleFiles"
      class="button is-primary mt-0"
      v-bind:class="{'is-loading': loading }"
      :disabled="!agreed"
    >
      {{ compressing ? 'Compressing...' : 'Upload files' }}
    </button>
    <canvas id="canvas" style="display: none;"></canvas>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'
// import imageCompression from 'browser-image-compression'

export default {
  name: 'FilesAdd',
  components: {
    CardComponent
  },
  data () {
    return {
      isMultipleFiles: false,
      loading: false,
      errorMessage: null,
      files: [],
      compressing: false,
      imageUrl: null,
      filename: null,
      mimeType: null,
      ext: null,
      image: null,
      mp3: null,
      mp3Url: null,
      mp3UrlForPreview: null,
      agreement: {
        one: false,
        two: false
      }
    }
  },
  methods: {
    pickFile () {
      this.loading = false
      this.imageUrl = null
      this.filename = null
      this.ext = null
      this.image = null
      this.mimeType = null
      this.$refs.fileInput.click()
    },
    filePicked (event) {
      this.files = event.target.files

      this.isMultipleFiles = this.files.length > 1
      if (this.isMultipleFiles) return

      const filename = this.files[0].name
      this.ext = filename.slice(filename.lastIndexOf('.'))
      this.mimeType = this.files[0].type
      this.filename = filename.slice(0, filename.lastIndexOf('.'))
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please add a valid file!')
      }
      const fileReader = new FileReader()
      if (this.mimeType.includes('image')) {
        fileReader.addEventListener('load', () => {
          this.imageUrl = fileReader.result
        })
        fileReader.readAsDataURL(this.files[0])
        this.image = this.files[0]
        if (this.mimeType !== 'image/gif') {
          this.compress()
        }
      } else if (this.mimeType.includes('video')) {
        // fileReader.addEventListener('load', () => {
        //   this.imageUrl = fileReader.result
        // })
        // fileReader.readAsDataURL(this.files[0])
        this.image = this.files[0]
        this.imageUrl = URL.createObjectURL(this.image)
      } else if (this.mimeType.includes('audio')) {
        fileReader.addEventListener('load', () => {
          this.mp3Url = fileReader.result
        })
        fileReader.readAsDataURL(this.files[0])
        this.mp3UrlForPreview = URL.createObjectURL(this.files[0])
        this.mp3 = this.files[0]
        this.mimeType = this.files[0].type
      }
      event.target.value = null
    },
    async compress () {
      const canvas = document.getElementById('canvas')
      const file = this.image
      const reader = new FileReader()
      reader.onload = (e) => {
        const img = new Image()
        img.onload = () => {
          const ctx = canvas.getContext('2d')
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)
          canvas.toBlob((blob) => {
            const newFile = new File([blob], 'image.webp', { type: 'image/webp' })
            this.image = newFile
            this.ext = '.webp'
            this.mimeType = 'image/webp'
          }, 'image/webp')
        }
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async uploadMultipleFiles () {
      for (const index in this.files) {
        const file = this.files[index]
        if (!file) continue
        const filename = file.name
        this.ext = filename.slice(filename.lastIndexOf('.'))
        this.mimeType = file.type
        this.filename = filename.split('.')[0]
        if (filename.lastIndexOf('.') <= 0) {
          return alert('Please add a valid file!')
        }
        const fileReader = new FileReader()
        if (!['.mp3', '.pdf'].includes(this.ext)) {
          fileReader.addEventListener('load', () => {
            this.imageUrl = fileReader.result
          })
          fileReader.readAsDataURL(file)
          this.image = file
          // if (this.mimeType !== 'image/gif') {
          //   await this.compress()
          // }
        } else {
          fileReader.addEventListener('load', () => {
            this.mp3Url = fileReader.result
          })
          fileReader.readAsDataURL(file)
          this.mp3UrlForPreview = URL.createObjectURL(file)
          this.mp3 = file
          this.mimeType = file
        }
        await this.upload()
      }
    },
    async upload () {
      console.log('hii')
      this.errorMessage = null
      this.filename = this.filename.replace(/['"]+/g, '')
      if (await this.checkForDuplicateFilenames(this.filename + this.ext)) {
        this.filename += ' - ' + Math.random().toString(36).substring(2, 6)
      }
      if (this.mimeType.includes('image') || this.mimeType.includes('video')) {
        await this.uploadImage()
      } else {
        await this.uploadMp3()
      }
      this.loading = false
      this.imageUrl = null
      this.filename = null
      this.ext = null
      this.image = null
      this.mimeType = null
      this.$buefy.toast.open({
        message: 'Successfully uploaded! Go to View Files to view it.',
        type: 'is-primary',
        queue: false
      })
    },
    async checkForDuplicateFilenames (filename) {
      const filesRef = firebaseApp.storage().ref().child(`files/${this.organisation.id}`)
      let found = false
      await filesRef.listAll()
        .then((res) => {
          res.items.forEach(itemRef => {
            if (filename === itemRef.name) {
              found = true
            }
          })
        })
      return found
    },
    async uploadImage () {
      this.loading = true
      await firebaseApp.storage()
        .ref(`files/${this.organisation.id}/${this.filename}${this.ext}`)
        .put(
          this.image,
          { contentType: this.mimeType }
        )
      await firebaseApp.storage()
        .ref(`files/${this.organisation.id}/${this.filename}${this.ext}`)
        .getDownloadURL().then(downloadURL => {
          this.$emit('uploaded')
        })
    },
    async uploadMp3 () {
      await firebaseApp.storage()
        .ref(`files/${this.organisation.id}/${this.filename}${this.ext}`)
        .put(
          this.mp3,
          { contentType: this.mimeType }
        )
      await firebaseApp.storage()
        .ref(`files/${this.organisation.id}/${this.filename}${this.ext}`)
        .getDownloadURL().then(downloadURL => {
          this.$emit('uploaded')
        })
    }
  },
  computed: {
    organisation () {
      return this.$store.state.organisation
    },
    exceedsFileSize () {
      if (this.image && this.image.size / 1024 / 1024 >= 20) {
        return true
      }
      if (this.mp3 && this.mp3.size / 1024 / 1024 >= 10) {
        return true
      }
      return false
    },
    imageSize () {
      return this.image ? (this.image.size / 1024 / 1024).toFixed(1) : null
    },
    mp3Size () {
      return this.mp3 ? (this.mp3.size / 1024 / 1024).toFixed(2) : null
    },
    agreed () {
      return this.agreement.one && this.agreement.two
    }
  }
}
</script>
