<template>
  <card-component :title="'Generate & improve text using AI'" icon="fa-file-image">
    <div class="mb-4">
      <div class="buttons mb-1">
        <div class="has-text-grey is-size-7 mr-2 mb-2">
          Tone:
        </div>
        <div
        v-for="(x, index) in tones"
        @click="tone = x"
        :key="index"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': tone === x}"
        >
        {{ x }}
        </div>
      </div>
      <div class="buttons mb-1">
        <div class="has-text-grey is-size-7 mr-2 mb-2">
          Audience:
        </div>
        <div
        v-for="(x, index) in audiences"
        @click="audience = x"
        :key="index"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': audience === x}"
        >
        {{ x }}
        </div>
      </div>
      <div class="buttons mb-1">
        <div class="has-text-grey is-size-7 mr-2 mb-2">
          Language Proficiency:
        </div>
        <div
        v-for="(x, index) in proficiencies"
        @click="proficiency = x"
        :key="index"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': proficiency === x}"
        >
        {{ x }}
        </div>
      </div>
      <div class="buttons">
        <div class="has-text-grey is-size-7 mr-2 mb-1">
          Translate to:
        </div>
        <div class="select" style="font-size: 13px;">
          <select v-model="language">
            <option v-for="x in languages" :key="x.key" :value="x.value">{{ x.key }}</option>
          </select>
        </div>
      </div>
    </div>
    <b-field
      label="Text"
      message="For certain key words or phrases that should not be rephrased, place [ and ] around it. Example: 'Make your way to the [Social Cafe]!'"
    >
      <b-input v-model="text" name="isActiveNotice" type="textarea"/>
    </b-field>
    <button
      @click="getGeneratedText"
      class="button is-primary mb-4"
      :class="{ 'is-loading': loading }"
      :disabled="loading || text.length === 0 || submitSeconds"
    >
      {{ !submitSeconds ? 'Generate' : `Please wait ${submitSeconds}`}}
    </button>
    <b-field
      label="Result"
      message="Output is not expected to be perfect 100% of the time. Make further changes before inserting into the text field."
    >
      <b-input
        v-model="resultText"
        name="isActiveNotice"
        placeholder="Generated text will appear here."
        type="textarea"
        :disabled="!resultText"
      />
    </b-field>
    <button
      @click="insert"
      class="button is-primary mb-4"
      :class="{ 'is-loading': loading }"
      :disabled="loading || resultText.length === 0"
    >
      Insert into text field
    </button>
    <!-- <div class="files">
      <div v-if="files.length === 0" @click="$router.push({ name: 'addImage' })" class="container has-text-grey-light has-text-centered is-clickable my-6 py-6">
        results will appear here
      </div>
      <div
      v-for="file, key in files"
      :key="key"
      class="p-2 mr-2 is-vcentered is-rounded"
      >
        <div class="has-text-centered">
          <img @click="selectFile(file)" :src="file.previewLink" class="files-image is-clickable">
        </div>
        <nav class="level mb-0">
          <div class="level-left">
            <div class="level-item">
              <div class="is-size-7">
                Photo by
                <a class="" :href="file.authorLink" target="_blank">
                {{ file.author }}
                </a>
                on
                <a :href="file.platformLink" target="_blank">{{ file.platform }}</a>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item ml-2">
            </div>
          </div>
        </nav>
      </div>
    </div> -->
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'TextGenerator',
  components: {
    CardComponent
  },
  props: {
    openedInCreator: {
      type: Boolean,
      default: false
    },
    formInputType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      submitSeconds: null,
      nextSubmitTime: null,
      getSubmitSecondsTextInterval: null,

      text: '',
      resultText: '',

      tones: ['Excited', 'Educational', 'Mysterious', 'Formal'],
      tone: 'Excited',
      audiences: ['Adults', 'Teenagers', 'Children'],
      audience: 'Adults',
      proficiencies: ['Elementary', 'Limited Working', 'Professional Working', 'Full Professional', 'Native'],
      proficiency: 'Professional Working',
      languages: [
        { key: 'None', value: null },
        { key: 'Amharic', value: 'am' },
        { key: 'Arabic', value: 'ar' },
        { key: 'Basque', value: 'eu' },
        { key: 'Bengali', value: 'bn' },
        { key: 'English (UK)', value: 'en-GB' },
        { key: 'Portuguese (Brazil)', value: 'pt-BR' },
        { key: 'Bulgarian', value: 'bg' },
        { key: 'Catalan', value: 'ca' },
        { key: 'Cherokee', value: 'chr' },
        { key: 'Croatian', value: 'hr' },
        { key: 'Czech', value: 'cs' },
        { key: 'Danish', value: 'da' },
        { key: 'Dutch', value: 'nl' },
        { key: 'English (US)', value: 'en' },
        { key: 'Estonian', value: 'et' },
        { key: 'Filipino', value: 'fil' },
        { key: 'Finnish', value: 'fi' },
        { key: 'French', value: 'fr' },
        { key: 'German', value: 'de' },
        { key: 'Greek', value: 'el' },
        { key: 'Gujarati', value: 'gu' },
        { key: 'Hebrew', value: 'iw' },
        { key: 'Hindi', value: 'hi' },
        { key: 'Hungarian', value: 'hu' },
        { key: 'Icelandic', value: 'is' },
        { key: 'Indonesian', value: 'id' },
        { key: 'Italian', value: 'it' },
        { key: 'Japanese', value: 'ja' },
        { key: 'Kannada', value: 'kn' },
        { key: 'Korean', value: 'ko' },
        { key: 'Latvian', value: 'lv' },
        { key: 'Lithuanian', value: 'lt' },
        { key: 'Malay', value: 'ms' },
        { key: 'Malayalam', value: 'ml' },
        { key: 'Marathi', value: 'mr' },
        { key: 'Norwegian', value: 'no' },
        { key: 'Polish', value: 'pl' },
        { key: 'Portuguese (Portugal)', value: 'pt-PT' },
        { key: 'Romanian', value: 'ro' },
        { key: 'Russian', value: 'ru' },
        { key: 'Serbian', value: 'sr' },
        { key: 'Chinese (PRC)', value: 'zh-CN' },
        { key: 'Slovak', value: 'sk' },
        { key: 'Slovenian', value: 'sl' },
        { key: 'Spanish', value: 'es' },
        { key: 'Swahili', value: 'sw' },
        { key: 'Swedish', value: 'sv' },
        { key: 'Tamil', value: 'ta' },
        { key: 'Telugu', value: 'te' },
        { key: 'Thai', value: 'th' },
        { key: 'Chinese (Taiwan)', value: 'zh-TW' },
        { key: 'Turkish', value: 'tr' },
        { key: 'Urdu', value: 'ur' },
        { key: 'Ukrainian', value: 'uk' },
        { key: 'Vietnamese', value: 'vi' },
        { key: 'Welsh', value: 'cy' }
      ],
      language: 'en',

      loading: false
    }
  },
  methods: {
    getGeneratedText () {
      localStorage.setItem('playtoursTextGenTone', this.tone)
      localStorage.setItem('playtoursTextGenAudience', this.audience)
      localStorage.setItem('playtoursTextGenProficiency', this.proficiency)
      localStorage.setItem('playtoursTextGenLanguage', this.language)

      this.files = []
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-ml-text',
        text: this.text,
        options: {
          tone: this.tone,
          audience: this.audience,
          proficiency: this.proficiency,
          language: this.language
        }
      }).then(result => {
        this.resultText = result.data.resultText.trim()
        this.loading = false
        this.startSubmitTimer()
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error!',
          type: 'is-danger',
          queue: false
        })
        this.loading = false
      })
    },
    insert () {
      this.$emit('insert', this.resultText)
    },
    startSubmitTimer () {
      const dt = new Date()
      dt.setSeconds(dt.getSeconds() + 20)
      this.nextSubmitTime = dt
      this.getSubmitSecondsText()
      this.getSubmitSecondsTextInterval = setInterval(() => {
        this.getSubmitSecondsText()
      }, 500)
    },
    getSubmitSecondsText () {
      const startTime = new Date()
      let msec = this.nextSubmitTime - startTime
      if (msec > 0) {
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000
        this.submitSeconds = `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
      } else {
        this.submitSeconds = null
        clearInterval(this.getSubmitSecondsTextInterval)
      }
    }
  },
  computed: {
    title () {
      return 'Generate & improve text using AI'
    },
    organisation () {
      return this.$store.state.organisation
    }
  },
  mounted () {
    if (localStorage.getItem('playtoursTextGenTone')) this.tone = localStorage.getItem('playtoursTextGenTone')
    if (localStorage.getItem('playtoursTextGenAudience')) this.audience = localStorage.getItem('playtoursTextGenAudience')
    if (localStorage.getItem('playtoursTextGenProficiency')) this.proficiency = localStorage.getItem('playtoursTextGenProficiency')
    if (localStorage.getItem('playtoursTextGenLanguage')) this.language = localStorage.getItem('playtoursTextGenLanguage')
  }
}
</script>

<style>
.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.files-image {
  max-width: 300px;
  max-height: 300px;
  border-radius: 0.4rem;
}

.search-input-modifier {
  max-width: 150px;
}
</style>
