<template>
  <card-component :title="title" icon="fa-file-image">
    <div v-if="files.length > 0 && loaded" class="mb-4">
      <div class="buttons">
        <div v-if="openedInCreator" @click="openAddFile()" class="button is-primary is-rounded is-small mt-0 mb-2">
          <i class="fas fa-upload mr-2"></i>
          Upload files
        </div>
        <div @click="getFiles()" class="button is-rounded is-small mt-0 mb-2">
          <i class="fas fa-sync-alt mr-2"></i>
          Refresh
        </div>
        <input class="input is-small is-rounded search-input-modifier mr-2 mb-2" type="tel" placeholder="type here to search..." v-model="searchString">
        <div @click="changeSortBy('uploadDate')" class="button is-rounded is-small mt-0 mb-2" :class="{'is-primary is-light': sortBy === 'uploadDate'}">
          <i class="fas fa-sort-numeric-down-alt mr-2"></i>
          Sort by Upload Date
        </div>
        <div @click="changeSortBy('name')" class="button is-rounded is-small mt-0 mb-2" :class="{'is-primary is-light': sortBy === 'name'}">
          <i class="fas fa-sort-alpha-down mr-2"></i>
          Sort by Name
        </div>
      </div>
    </div>
    <div class="files">
      <div v-if="files.length === 0 && !loaded" class="container has-text-centered">
        <i class="spinning fas fa-4x fa-circle-notch pointer has-text-grey-light"></i>
      </div>
      <div v-if="files.length === 0 && loaded" @click="$router.push({ name: 'addImage' })" class="container has-text-grey-light has-text-centered is-clickable">
        No files added - go to Add Files to proceed or click here.
      </div>
      <div
      @click="openedInCreator ? selectFile(file) : null"
      v-for="file, key in sortedFiles"
      :key="key"
      class="card p-2 mr-2 is-vcentered is-rounded"
      :class="{
        'is-clickable': openedInCreator
      }"
      >
        <nav class="level mb-0">
          <div class="level-left">
            <div class="level-item">
              <div class="mb-2">
                {{ file.filename }}
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item ml-2">
              <div @click="deleteFile(file)" v-if="!openedInCreator" class="container has-text-centered">
                <i class="fas fa-trash has-text-danger is-clickable"></i>
              </div>
            </div>
          </div>
        </nav>
        <div class="has-text-centered">
          <img v-if="file.metadata.contentType.includes('image')" :src="file.url" class="files-image">
          <video v-if="file.metadata.contentType.includes('video')" controls class="files-video">
            <source :src="file.url" type="video/mp4">
            <source :src="file.url" type="video/ogg">
            Your browser does not support the video tag.
          </video>
          <audio v-if="file.metadata.contentType === 'audio/mpeg'" controls="controls">
            <source :src="file.url" type="audio/mpeg">
          </audio>
          <div v-if="file.metadata.contentType === 'application/pdf'">
            <iframe :src="file.url" width="100%" height="250px">
            </iframe>
          </div>
        </div>
      </div>
    </div>
    <div @click="showMore()" v-if="showFirstN < files.length && loaded" class="container has-text-centered mt-5">
      <div class="button is-primary is-small is-rounded">
        Show more
      </div>
    </div>
    <div @click="showAll()" v-if="showFirstN < files.length && loaded" class="container has-text-centered mt-2">
      <div class="has-text-grey-light is-size-7 is-clickable">
        Show all
      </div>
    </div>

    <div class="modal" :class="{'is-active': showAddFileModal}">
      <div class="modal-background" @click="showAddFileModal = False"></div>
      <div class="modal-content px-6">
        <files-add v-on:uploaded="() => { this.showAddFileModal = false; getFiles() }" class="mt-3"/>
      </div>
      <button @click="showAddFileModal = False" class="modal-close is-large" aria-label="close"></button>
    </div>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'
import FilesAdd from '@/components/FilesAdd'

export default {
  name: 'FilesView',
  components: {
    CardComponent,
    FilesAdd
  },
  props: {
    openedInCreator: {
      type: Boolean,
      default: false
    },
    formInputType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loaded: false,
      files: [],
      searchString: null,
      sortBy: 'uploadDate',
      showFirstN: 15,
      showAddFileModal: false
    }
  },
  methods: {
    showMore () {
      this.showFirstN = this.showFirstN + 15
    },
    showAll () {
      this.showFirstN = this.files.length
    },
    changeSortBy (type) {
      this.sortBy = type
    },
    getFiles () {
      this.files = []
      this.loaded = false
      const storage = firebaseApp.storage()
      const storageRef = storage.ref()
      const filesRef = storageRef.child(`files/${this.organisation.id}`)
      filesRef.listAll()
        .then((res) => {
          this.files = []
          res.items.forEach(async (itemRef) => {
            const ext = itemRef.fullPath.slice(itemRef.fullPath.lastIndexOf('.'))
            const url = await itemRef.getDownloadURL()
            const metadata = await itemRef.getMetadata()
            this.files.push({
              fullPath: itemRef.fullPath,
              filename: itemRef.name,
              url,
              ext,
              ref: itemRef,
              metadata
            })
          })
          setTimeout(() => {
            this.loaded = true
          }, 1500)
        }).catch(() => {
          // Uh-oh, an error occurred!
        })
    },
    selectFile (fileMetadata) {
      this.$emit('selectFile', fileMetadata)
    },
    deleteFile (fileMetadata) {
      const confirmed = confirm('Confirm deletion? If deleted, PlayTours using the file will see a blank instead.')
      if (!confirmed) { return }
      const storage = firebaseApp.storage()
      const storageRef = storage.ref()
      const fileRef = storageRef.child(fileMetadata.fullPath)
      fileRef.delete().then(() => {
        this.files = this.files.filter(el => {
          return el.fullPath !== fileMetadata.fullPath
        })
      })
    },
    openAddFile () {
      if (this.openedInCreator) {
        this.showAddFileModal = true
      }
      // const routeData = this.$router.resolve({ name: 'addImage' })
      // window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    setTimeout(() => {
      this.loaded = true
    }, 2000)
    this.getFiles()
  },
  computed: {
    title () {
      if (this.openedInCreator) {
        return 'Select file'
      } else {
        return 'View files'
      }
    },
    organisation () {
      return this.$store.state.organisation
    },
    sortedFiles () {
      return [...this.files].sort((a, b) => {
        if (this.sortBy === 'name') {
          if (a.filename < b.filename) {
            return -1
          }
          if (a.filename > b.filename) {
            return 1
          }
          return 0
        } else if (this.sortBy === 'uploadDate') {
          if (a.metadata.timeCreated < b.metadata.timeCreated) {
            return 1
          }
          if (a.metadata.timeCreated > b.metadata.timeCreated) {
            return -1
          }
          return 0
        }
      }).filter(file => {
        if (this.formInputType === 'image-url') {
          return !['audio/mpeg'].includes(file.metadata.contentType)
        } else if (this.formInputType === 'mp3-url') {
          return ['audio/mpeg'].includes(file.metadata.contentType)
        }
        return true
      }).filter(file => {
        if (this.searchString && this.searchString.length > 0) {
          return file.filename.toUpperCase().includes(this.searchString.toUpperCase())
        } else {
          return file
        }
      }).slice(0,
        this.showFirstN
      )
    }
  }
}
</script>

<style>
.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.files-image {
  max-width: 150px;
  max-height: 150px;
}

.files-video {
  max-width: 250px;
  max-height: 150px;
}

.search-input-modifier {
  max-width: 150px;
}
</style>
