<template>
  <div class="pb-5">
    <div class="pt-5 px-5 mb-3 is-flex is-justify-content-space-between is-align-items-end">
      <div class="is-size-7 has-text-grey-light pr-3">
        Contents will be automatically deleted 90 days after it was uploaded by the team/player.
      </div>
      <div
        @click="openTabAll()"
        class="button is-primary is-small is-rounded mb-1 mr-1"
        >
        Click here to download {{ !teamNameFilter && !taskNameFilter ? 'all' : 'filtered' }} content
      </div>
    </div>
    <div v-if="teamNames.length > 0" class="px-5 mb-3">
      <div class="buttons">
        <div
        @click="teamNameFilter = null"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': !teamNameFilter }"
        >
          ALL TEAMS
        </div>
        <div
        v-for="(teamName, index) in teamNames"
        @click="teamNameFilter = teamName"
        :key="index"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': teamNameFilter === teamName}"
        >
          {{ teamName }}
        </div>
      </div>
    </div>
    <div v-if="taskNames.length > 0" class="px-5 mb-3">
      <div class="buttons">
        <div
        @click="taskNameFilter = null"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': !taskNameFilter }"
        >
          ALL TASKS
        </div>
        <div
        v-for="(taskName, index) in taskNames"
        @click="taskNameFilter = taskName"
        :key="index"
        class="button is-small is-rounded mb-1 mr-1"
        :class="{'is-primary': taskNameFilter === taskName}"
        >
          {{ taskName }}
        </div>
      </div>
    </div>
    <div class="is-flex is-flex-wrap-wrap px-5">
      <div v-for="(photo, index) in shownGalleryPhotos" :key="index" class="card mr-1 photo-gallery-card-modifier">
        <div class="card-image">
          <figure class="image">
            <div class="box m-2 has-text-centered is-size-5" v-if="photo.mimeType === 'text/plain' || !photo.url.includes('http')">
              {{ photo.url }}
            </div>
            <img v-else-if="!photo.mimeType || photo.mimeType.includes('image')" class="gallery-content-modifier" :src="photo.url" :alt="photo.teamName">
            <video v-else-if="photo.mimeType && photo.mimeType.includes('video')" class="gallery-content-modifier" :src="photo.url" width="320" height="240" controls>
              Your browser does not support the video tag.
            </video>
            <audio v-else-if="photo.mimeType && photo.mimeType.includes('audio')" class="p-2" style="width: 100%;" controls="controls">
              <source :src="photo.url" type="audio/mpeg">
            </audio>
            <FilePreview v-else :file="photo" />
          </figure>
        </div>
        <div class="card-content px-3 py-4">
          <div class="media">
            <div class="media-content">
              <p class="title is-5">{{ photo.teamName }}</p>
              <p class="subtitle is-6 mb-1">"{{ photo.taskName }}" in stage {{ photo.stage }}</p>
              <p class="subtitle is-7">{{ (new Date(photo.createdAt.seconds ? photo.createdAt.seconds * 1000 : photo.createdAt._seconds * 1000)).toLocaleString('en-SG') }}</p>
            </div>
          </div>
          <div v-if="photo.mimeType !== 'text/plain'" class="is-flex is-justify-content-space-between" style="gap: 1rem;">
            <div v-if="photo.votes && photo.votes.length > 0" class="has-text-7 my-0 py-0">
              {{ photo.votes.length }}<i class="fas fa-thumbs-up mx-1"></i> by {{ photo.votes.join(', ') }}
            </div>
            <div>
              <i @click="openTab(photo)" class="fas fa-download is-clickable mr-2"></i>
              <i v-if="session" @click="deleteMedia(photo)" class="has-text-danger is-clickable fas fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="loadAll = true" v-if="!loadAll && this.galleryPhotos.length > 5 && !this.teamNameFilter && !this.taskNameFilter" class="container has-text-centered mt-5">
      <div class="button is-primary is-small is-rounded">
        Load {{ this.galleryPhotos.length - 5 }} more ...
      </div>
    </div>
  </div>
</template>

<script>
import FilePreview from '@/components/FilePreview'
import firebaseApp from '@/firebase/init'

export default {
  name: 'SessionPhotoGallery',
  components: {
    FilePreview
  },
  props: ['galleryPhotos', 'session'],
  data () {
    return {
      loadAll: false,
      teamNameFilter: null,
      taskNameFilter: null
    }
  },
  computed: {
    shownGalleryPhotos () {
      const galleryPhotos = [...new Map(this.galleryPhotos.map(item => [item.url, item])).values()]
      console.log('galleryPhotos', galleryPhotos)
      return galleryPhotos.slice(0,
        this.loadAll || this.teamNameFilter || this.taskNameFilter ? galleryPhotos.length : 5
      ).filter(entry => {
        return this.teamNameFilter ? entry.teamName === this.teamNameFilter : true
      }).filter(entry => {
        return this.taskNameFilter ? entry.taskName === this.taskNameFilter : true
      })
    },
    teamNames () {
      return [...new Set(this.galleryPhotos.map(entry => entry.teamName))]
    },
    taskNames () {
      return [...new Set(this.galleryPhotos.map(entry => entry.taskName))]
    }
  },
  methods: {
    openTab (photo) {
      // window.open(url)
      let filename = photo.filename
      if (!filename) {
        let ext = '.jpg'
        if (photo.mimeType && photo.mimeType.includes('video')) {
          ext = '.mp4'
        } else if (photo.mimeType && photo.mimeType.includes('audio')) {
          ext = '.wav'
        } else {
          const mimeTypes = {
            'application/pdf': '.pdf',
            'application/zip': '.zip',
            'application/msword': '.doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
            'application/vnd.ms-excel': '.xls',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
            'application/vnd.ms-powerpoint': '.ppt',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
            'text/plain': '.txt',
            'image/jpeg': '.jpg',
            'image/png': '.png',
            'image/gif': '.gif',
            'image/bmp': '.bmp',
            'image/webp': '.webp',
            'video/mp4': '.mp4',
            'video/x-msvideo': '.avi',
            'video/quicktime': '.mov',
            'video/x-ms-wmv': '.wmv',
            'video/x-flv': '.flv',
            'video/x-matroska': '.mkv'
          }
          ext = mimeTypes[photo.mimeType] || ''
        }
        filename = `${photo.teamName.replace(/[^\w ]/g, '').replaceAll('undefined', '')} - ${photo.taskName.replace(/[^\w ]/g).replaceAll('undefined', '')}${ext}`
      }
      this.downloadFromUrl(photo.url, filename)
    },
    sleep (ms) {
      const start = new Date().getTime()
      const expire = start + ms
      while (new Date().getTime() < expire) { }
    },
    openTabAll () {
      let timeoutTime = 500
      this.galleryPhotos.filter(entry => {
        return this.teamNameFilter ? entry.teamName === this.teamNameFilter : true
      }).filter(entry => {
        return this.taskNameFilter ? entry.taskName === this.taskNameFilter : true
      }).forEach((galleryPhoto, index) => {
        // window.open(galleryPhoto.url)
        let filename = galleryPhoto.filename
        if (!filename) {
          let ext = '.jpg'
          if (galleryPhoto.mimeType && galleryPhoto.mimeType.includes('video')) {
            ext = '.mp4'
          } else if (galleryPhoto.mimeType && galleryPhoto.mimeType.includes('audio')) {
            ext = '.wav'
          } else {
            const mimeTypes = {
              'application/pdf': '.pdf',
              'application/zip': '.zip',
              'application/msword': '.doc',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
              'application/vnd.ms-excel': '.xls',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
              'application/vnd.ms-powerpoint': '.ppt',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
              'text/plain': '.txt',
              'image/jpeg': '.jpg',
              'image/png': '.png',
              'image/gif': '.gif',
              'image/bmp': '.bmp',
              'image/webp': '.webp',
              'video/mp4': '.mp4',
              'video/x-msvideo': '.avi',
              'video/quicktime': '.mov',
              'video/x-ms-wmv': '.wmv',
              'video/x-flv': '.flv',
              'video/x-matroska': '.mkv'
            }
            ext = mimeTypes[galleryPhoto.mimeType] || ''
          }
          filename = `${galleryPhoto.teamName.replace(/[^\w ]/g, '').replaceAll('undefined', '')} - ${galleryPhoto.taskName.replace(/[^\w ]/g).replaceAll('undefined', '')}${ext}`
        }
        timeoutTime += 700
        setTimeout(
          () => {
            this.downloadFromUrl(galleryPhoto.url, filename)
          }, timeoutTime
        )
      })
    },
    downloadFromUrl (url, filename) {
      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          // the filename you want
          a.download = filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => alert('Too many downloads at the same time.'))
    },
    deleteMedia (photo) {
      const confirmed = confirm('Confirm delete?')
      if (!confirmed) return

      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'delete-media',
        sessionId: this.session.id,
        imageUrls: [photo.url]
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Successfully deleted!',
          type: 'is-primary',
          queue: false
        })
      }).catch(_err => {
        this.$buefy.toast.open({
          message: 'There was an error.',
          type: 'is-danger',
          queue: false
        })
      })
    }
  }
}
</script>

<style>
.photo-gallery-card-modifier {
  width: 32%;
  border-radius: 10px;
}

.gallery-content-modifier {
  border-radius: 10px 10px 0px 0px !important;
}
</style>
