<template>
  <div class="container p-3">
    <div class="m-2 has-text-centered is-size-5 is-flex is-flex-direction-column">
      <i class="fas fa-file-image fa-2x mb-2"></i>
      <div v-if="file.filename" class="is-size-6">{{ file.filename }}</div>
      <a :href="file.url" target="_blank" class="is-size-7">Click here to download</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilePreview',
  props: ['file']
}
</script>
