<template>
  <card-component :title="title" icon="fa-file-image">
    <div class="mb-4">
      <div class="buttons">
        <input class="input is-small is-rounded search-input-modifier mr-2 mb-2" type="tel" placeholder="type here to search..." v-model="searchString">
        <button
          :disabled="!searchString"
          @click="getFiles()"
          class="button is-primary is-rounded is-small mt-0 mb-2"
          :class="{'is-loading': loading}"
        >
          <i class="fas fa-search mr-2"></i>
          Search
        </button>
      </div>
    </div>
    <div class="files">
      <div v-if="files.length === 0" @click="$router.push({ name: 'addImage' })" class="container has-text-grey-light has-text-centered is-clickable my-6 py-6">
        results will appear here
      </div>
      <div
      v-for="file, key in files"
      :key="key"
      class="p-2 mr-2 is-vcentered is-rounded"
      >
        <div class="has-text-centered">
          <img @click="selectFile(file)" :src="file.previewLink" class="files-image is-clickable">
        </div>
        <nav class="level mb-0">
          <div class="level-left">
            <div class="level-item">
              <div class="is-size-7">
                Photo by
                <a class="" :href="file.authorLink" target="_blank">
                {{ file.author }}
                </a>
                on
                <a :href="file.platformLink" target="_blank">{{ file.platform }}</a>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item ml-2">
            </div>
          </div>
        </nav>
      </div>
    </div>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'UnsplashView',
  components: {
    CardComponent
  },
  props: {
    openedInCreator: {
      type: Boolean,
      default: false
    },
    formInputType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      page: 1,
      files: [],
      searchString: null
    }
  },
  methods: {
    getFiles () {
      this.files = []
      this.loading = true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'image-search',
        query: this.searchString
      }).then(result => {
        this.files = result.data.results
        this.loading = false
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      })
    },
    triggerUnsplashDownload (file) {
      if (file.platform !== 'Unsplash') return true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'unsplash-trigger-download',
        unsplashDownloadLink: file.unsplashDownloadLink
      })
    },
    selectFile (file) {
      this.$emit('selectFile', file)
      this.triggerUnsplashDownload(file)
    }
  },
  computed: {
    title () {
      return 'Use a photo from the Internet'
    },
    organisation () {
      return this.$store.state.organisation
    }
  }
}
</script>

<style>
.files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.files-image {
  max-width: 300px;
  max-height: 300px;
  border-radius: 0.4rem;
}

.search-input-modifier {
  max-width: 150px;
}
</style>
